async function filter() {

    var $ = require("../../../config/node_modules/jquery");

    $(function() {
        var count = 0;
        filter(); 
        $(".category input[type=checkbox]").change(filter);
    
        function filter() {
            count = ++count;
            var sessionValue = window.location.search.split("=").pop();
            var sessionInput = $('input[data-slug="'+ sessionValue +'"]');
         
            if (sessionValue != undefined && count == 1){
                var thisElem = $(sessionInput);
                $(sessionInput).prop("checked",true);
            } else {
                var thisElem = $(this);
            }
    
            var radiobehaviour = $(thisElem).attr("name");
    
            if (radiobehaviour === 'radio' ){
                var group = "input:checkbox[name='"+$(thisElem).attr("name")+"']";
                if ( $(thisElem).parents('.category').first()){
                    var all = $(thisElem);
                } else {
                    var all = $(thisElem).parents('.category').siblings('.category').first();
                }
                $(group).parents('.category').removeClass('checked');
                if ($(thisElem).prop("checked") === true ){
                    $(group).prop("checked",false);
                    $(thisElem).prop("checked",true); 
                    $(thisElem).parents('.category').addClass('checked'); 
                } else { 
                    $(group).prop("checked",false);
                    $(all).find('input').prop("checked",true);
                    $(all).addClass('checked');
                }
            }
    
            var slugs = $('.category input[type=checkbox]:checked').map(function(){return $(thisElem).attr("data-slug")}).get().join(",");
            // console.log(slugs)
    
            if (slugs !== ''){
                var get_url = "";
                get_url = "/filters?categories="+slugs;
            
                            
                window.history.pushState(null, null, "?categories=" + slugs);
                $.ajax({url: get_url , success: function(result){
                    $("#items").html(result).hide().fadeIn(500);
                }});
    
            }   
        };
    });
} export {
    filter
}