import gsap from '../../../config/node_modules/gsap';
var $ = require("../../../config/node_modules/jquery");
gsap.config({nullTargetWarn: false});
var contentTl = gsap.timeline();

async function contentAnimationIn() {
    $(document).ready( function () {
        if ( $('.title-in')[0] ){
            var words = document.querySelectorAll('.title-in h1 .word span');
            var wordsArray = [].slice.call(words);
            
            contentTl.to(wordsArray, {
                duration: .8,
                y: "0",
                opacity: "1",
                ease: "Power4.easeOut",
                stagger: 0.13,
                delay: 1,
            }, '-=1.9');
        }
    });
} export {
    contentAnimationIn
} 