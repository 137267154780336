
var $ = require("../../../config/node_modules/jquery");
// Require jQuery (Fancybox dependency)
window.$ = window.jQuery = require('../../../config/node_modules/jquery');

// Fancybox
const fancybox = require('../../../config/node_modules/@fancyapps/fancybox');
// Fancybox Stylesheet
// const fancyboxCSS = require('../../../config/node_modules/!style!css!@fancyapps/fancybox/dist/jquery.fancybox.css');

async function gallery() {

    $( document ).ready(function() {
        $('.fancybox').fancybox();
    });


} export {
    gallery
}