
var $ = require("../../../config/node_modules/jquery");
var jQueryBridget = require('../../../config/node_modules/jquery-bridget');
var Masonry = require("../../../config/node_modules/masonry-layout");
import "../../../config/node_modules/bootstrap/js/dist/tooltip";

function writeCookie(name,value,days) {
    var date, expires;
    if (days) {
        date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        expires = "; expires=" + date.toGMTString();
            }else{
        expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var i, c, ca, nameEQ = name + "=";
    ca = document.cookie.split(';');
    for(i=0;i < ca.length;i++) {
        c = ca[i];
        while (c.charAt(0)==' ') {
            c = c.substring(1,c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length,c.length);
        }
    }
    return '';
}

$(document).ready( function () {
    if(parent.document.getElementById('config-bar')) {
        $('html').addClass('config-mode');
    } else {
    }
    if ($("#colors").length > 0){ 
        rowColorFill();
    }
    if ($(".password-check").length > 0){ 
        showPassword()
    }

    var ctaShow = document.getElementsByTagName('body')[0].dataset['cta'];
    if (ctaShow === 'true'){
        $('.sticky-cta').addClass('show');
    }
})



jQueryBridget( 'masonry', Masonry, $ );

$('.gallery').masonry({
    columnWidth: 1,
    percentPosition: true
});

// $('[data-toggle="tooltip"]').tooltip();

var ctastate = readCookie('ctaClose');
if (ctastate === 'true'){
    $('.sticky-cta').remove();
}

var closeButtons = $('.close-cta');
closeButtons.on('click', function() {
    writeCookie('ctaClose', true, 1);
    
    $('.sticky-cta').addClass('hide-cta');
    $('.sticky-cta').removeClass('show');
});


$(".scrolldown").click(function() {
    var navHeight = $('.nav').height();
    $('html,body').animate({
        scrollTop: $("section").first().offset().top - navHeight
    }, 'fast');
});

$('.card').on('click', function(){
    if(!$(this).hasClass('active') ){  
        $(this).siblings().removeClass('active');
        $(this).addClass('active');
    } else {
        $(this).removeClass('active'); 
    }    
});


$(window).scroll(function() {
    var wScroll = $(window).scrollTop();
    $(".horizontal-text").each(function(){
        // console.log($(this).offset().top)
        $(this).css({
            transform: "translateX(-" + wScroll / 23 + "%)  translateY(-50%)"
        })    
    })
    
});

function rowColorFill() {
    var rowColors = $('#colors').attr('data-color');
    var rowColorsSingle = rowColors.split('||');

    // console.log(rowColorsSingle);

	$('.project-tray .plate--row').each(function(i){
        var rowColorClass = 'color__background';
        $(this).append('<div class="' + rowColorClass + '"></div>');
        
        // console.log($(this).find('.color__background').css('background', rowColorsSingle[i]))    
    });
}


$(".thumbnail").click(function() {
    $(this).addClass('active-vid')
});

export const setActive = (el, active) => {
    const formField = el.parentNode;
    if (active) {
        formField.classList.add('form-field--is-active');
    } else {
        formField.classList.remove('form-field--is-active');
        el.value === '' ?
            formField.classList.remove('form-field--is-filled') :
            formField.classList.add('form-field--is-filled');
    }
};

