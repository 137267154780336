
async function navigation () {
    
    var $ = require("../../../config/node_modules/jquery");

    $(document).ready(function() {

        $('.navbar-toggler').click(function() {
            $('.navigation').toggleClass('menu-open');
            $('.navigation').removeClass('pushed');
        });        

        $(".dropdown-arrow").on("click", function() {
            $('.navigation').addClass('pushed');
            var dropId = $(this).attr('data-hoofdmenu');
            // console.log(dropId)
            $( ".mega-menu" ).each(function( index ) {
                var thisDropId = $(this).attr('data-megamenu')
                $(this).hide();
                // console.log(dropId)
               
                if (dropId === thisDropId){
                  $(this).show();
                }
            })
        });

        $(".disable-cart").on("click", function() {
            let body = document.querySelector('body');
			body.classList.remove("shop-dropdown-show");
        });
        
            
        $(".back-main-menu").on("click", function() {
            $('.navigation').removeClass('pushed');
        });

        $(".to--mainmenu").on("click", function() {
            $('.main-menu').removeClass('pushed');
        });

        if ($(window).width() <= 992) {
        } else {
            
            setTimeout(function(){
                $('.navbar_nav').find('.nav_item.main-nav').each(function() {
                    if ($(this).hasClass('dropdown')) {
                        let hoofdmenuXID = $(this).children('.nav_link').attr('data-hoofdmenu');
                        let XcurrentMegaMenu = $('.mega-menu[data-megamenu = ' + hoofdmenuXID + ']');
                    
                        let currentActiveDropdown = $(this).offset().left;
                        let currentActiveDropdownMenu = $(XcurrentMegaMenu).find($('.mega-menu__items'));
                        let currentActiveDropdownMenuItems = currentActiveDropdownMenu.offset().left;
                        let currentActiveDropdownOffset = currentActiveDropdown - currentActiveDropdownMenuItems;
                        // console.log(currentActiveDropdownOffset)
                        
                        $(currentActiveDropdownMenu).css("margin-left", currentActiveDropdownOffset + 'px');
                    }
                }) 
            }, 2000);
            
            
            var menu = $(".navbar_nav");
            if ($("li.main-nav.active")[0]){
                var indicator = $('<div class="indicator"></div>');
                menu.append(indicator);
                position_indicator(menu.find("li.main-nav.active"));  
                menu.find("li.main-nav").mouseenter(function(){
                    position_indicator($(this));
                });
                
                menu.find("li.main-nav").mouseleave(function(){
                    position_indicator(menu.find("li.main-nav.active"));
                });
                
                function position_indicator(ele){
                    var conleft = $('.navbar_collapse').offset().left;
                    var left = ele.offset().left;
                    var width = ele.width();
                    var widthhalf =  width / 2;
                    var centerLeft = conleft - left - widthhalf;
                    indicator.stop().animate({
                        left: Math.abs(centerLeft),
                        width: 5
                    });
                }
            }

            $('.nav_item.main-nav').on('mouseenter', function() {
                var nav = $('.navigation');
                var megaMenuWrapper = $('.mega-menu-wrapper');
                var otherMegaMenus = $('.mega-menu');
                var megaMenuContainer = $('.mega-menu-container');
                var megaMenuContainerInner = $('.navbar_inner').outerHeight(true);

    
                $('.nav_item.main-nav').removeClass('hovered-nav-item');
                $(this).addClass('hovered-nav-item');
    
                if ($(this).hasClass('dropdown')) {
                    let hoofdmenuID = $(this).children('.nav_link').attr('data-hoofdmenu');
                    let currentMegaMenu = $('.mega-menu[data-megamenu = ' + hoofdmenuID + ']');
    
                    $(nav).addClass('mega-menu__active');
                    $('body').addClass('mega-menu__overlay');
    
                    let currentMegaMenuHeight = $(currentMegaMenu).outerHeight(true);
                    $(megaMenuWrapper).css("height", currentMegaMenuHeight + 'px');
    
                    $(otherMegaMenus).removeClass('active');
                    $(currentMegaMenu).addClass('active');

                    if ($('body').hasClass('nav-relative') && $(megaMenuContainer).hasClass('mega--active')){
                        $(megaMenuContainer).css("top", megaMenuContainerInner + 'px');
                    } 
                } else {
                    $(otherMegaMenus).removeClass('active');

                    $(nav).removeClass('mega-menu__active');
                    $('body').removeClass('mega-menu__overlay');
                }

              
            });
    
            $('.navigation').on('mouseleave', function() {
                var nav = $('.navigation');
                var otherMegaMenus = $('.mega-menu');
    
                $('.nav_item.main-nav').removeClass('hovered-nav-item');
    
                $(otherMegaMenus).removeClass('active');
                $(nav).removeClass('mega-menu__active');
                $('body').removeClass('mega-menu__overlay');
            });

            var lastScrollTop = 600;
            $(window).scroll(function(event){
                var nav = $('.navigation');
                var st = $(this).scrollTop();
                if (st > lastScrollTop){
                    $(nav).removeClass('scroll-up');
                    $(nav).addClass('scroll-down');
                } else {
                    $(nav).removeClass('scroll-down');
                    $(nav).addClass('scroll-up');
                }
                lastScrollTop = st;
            });
        }    

        $(window).scroll(function(){
            var offsetTop = $(window).scrollTop();
            var scrolled = offsetTop;
        
            if (scrolled >= 1) {
                $('.navigation').addClass("fixed-header");
            } else {
                $('.navigation').removeClass("fixed-header");
            }
        });
    });

 } export {
    navigation
 }
