
import {setActive} from './functions';
import '../../../config/node_modules/bootstrap/js/dist/collapse';
var $ = require("../../../config/node_modules/jquery");

async function general() {
 

    $(document).ready( function () {
        /* FORM LABELS */ 
        [].forEach.call(
            document.querySelectorAll('.form-field__label, .form-field input, .form-field__textarea'),
            el => {
                el.onblur = () => {
                    setActive(el, false);
        
                };
                el.onfocus = () => {
                    setActive(el, true);
                };
        });

        
    });

  
    if($('.grid .galerij-image')[0]){
        setInterval(function(){
            $('.grid .galerij-image').each(function (i, element) {        
                resizeGridItem(element);
            });
        }, 300);

        function resizeGridItem(element) {
            let item = $(element).outerHeight(true);
            let grid = document.getElementsByClassName("grid")[0];
            console.log(grid);
            let rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
            let rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
            let rowSpan = Math.ceil((item + rowGap) / (rowHeight + rowGap));
            element.style.gridRowEnd = "span " + rowSpan;
        }
    }
  
    
    
    
} export {
    general
}