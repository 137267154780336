// Import Swiper and modules
import {
	Swiper,
	Navigation,
	Pagination,
	Scrollbar,
	Controller,
	Mousewheel,
	Autoplay,
	EffectFade,
} from '../../../config/node_modules/swiper/swiper.esm';

Swiper.use([Navigation, Pagination, Scrollbar, Controller, Mousewheel, Autoplay, EffectFade]);

var projectSliders = document.querySelectorAll('.project_slider');
if (typeof(projectSliders) != 'undefined' && projectSliders != null) {
	projectSliders.forEach((projectSlider) => 
		projectSlider = new Swiper (projectSlider, {
		direction: "horizontal",
		loop: false,
		slidesPerView: 1,
		grabCursor: true,
		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
		},
		navigation: {
			nextEl: projectSlider.querySelector(".swiper-button-next"),
			prevEl: projectSlider.querySelector(".swiper-button-prev"),
		},
		spaceBetween: 20,
		scrollbar: {
			el: projectSlider.querySelector('.swiper-scrollbar'),
			draggable: true,
		},
		breakpoints: {
			0: {
			slidesPerView: 1.5,
			},
			767: {
			slidesPerView: 2,
			},
			1200: {
			slidesPerView: 3,
			}
		}
	})
)}


// if (document.getElementsByClassName('project_slider')[0]){
// 	var proj_swiper_between = parseInt(document.getElementsByClassName('project_slider')[0].getAttribute('space-between'));
// 	var proj_swiper_amount = parseInt(document.getElementsByClassName('project_slider')[0].getAttribute('slides-size'));

// 	var proj_swiper = new Swiper('.project_slider', {
// 		slidesPerView: 1,
// 		spaceBetween: 20,
// 		navigation: {
// 			nextEl: '.swiper-button-itm-next',
// 			prevEl: '.swiper-button-itm-prev',
// 		},
// 		scrollbar: {
// 			el: '.swiper-scrollbar',
// 			hide: true,
// 		},
// 		mousewheel: {
// 			forceToAxis: true, 
// 		},
// 		breakpoints: {
// 			768: {
// 				slidesPerView: proj_swiper_amount,
// 				slidesPerGroup: proj_swiper_amount,
// 				spaceBetween: proj_swiper_between,
// 			},
// 		}
// 	});
// }

var rela_swiper = new Swiper('.related__products', {
	slidesPerView: 1,
	spaceBetween: 20,
	scrollbar: {
	  el: '.swiper-scrollbar',
	  hide: true,
	},
	mousewheel: {
		forceToAxis: true, 
	},
	breakpoints: {
        768: {
			slidesPerView: 3,
			slidesPerGroup: 1,
        },
	},
});

var prod_swiper = new Swiper('.product_slider', {
	slidesPerView: 1,
	spaceBetween: 20,
	scrollbar: {
	  el: '.swiper-scrollbar',
	  hide: true,
	},
	mousewheel: {
		forceToAxis: true, 
	},
	breakpoints: {
        768: {
			slidesPerView: 1,
			slidesPerGroup: 1,
        },
	}
});

if (document.getElementsByClassName('testimonial_slider')[0]){
	var tesm_swiper_amount = parseInt(document.getElementsByClassName('testimonial_slider')[0].getAttribute('slides-size'));
	var tesm_swiper = new Swiper('.testimonial_slider', {
		slidesPerView: 1,
		spaceBetween: 20,
		slidesPerGroup: 1,
		loopFillGroupWithBlank: false,
		pagination: {
		el: '.swiper-pagination-testimonial',
		clickable: true,
		},
		mousewheel: {
			forceToAxis: true, 
		},
		breakpoints: {
			768: {
				slidesPerView: tesm_swiper_amount,	
			},
		},
		navigation: {
			nextEl: '.swiper-button-test-next',
			prevEl: '.swiper-button-test-prev',
		}
	});
}


var revi_swiper = new Swiper('.review_slider', {
	slidesPerView: 1,
	spaceBetween: 40,
	navigation: {
        nextEl: '.swiper-button-rev-next',
        prevEl: '.swiper-button-rev-prev',
	},
	mousewheel: {
		forceToAxis: true, 
	},
	effect: 'fade',
	fadeEffect: {
	  crossFade: true
	}
});

var head_swiper = new Swiper('.header_slider.header_slider_no_fade', {
	slidesPerView: 1,
	spaceBetween: 0,
	pagination: {
	  el: '.swiper-pagination-header',
	  clickable: true,
	},
	mousewheel: {
		forceToAxis: true, 
	},
	autoplay: {
        delay: 8000,
	},
	loop: true
});

var head_swiper_fade = new Swiper('.header_slider.header_slider_fade', {
	slidesPerView: 1,
	spaceBetween: 0,
	pagination: {
	  el: '.swiper-pagination-header',
	  clickable: true,
	},
	effect: 'fade',
	fadeEffect: {
	  crossFade: true
	},
	mousewheel: {
		forceToAxis: true, 
	},
	autoplay: {
        delay: 8000,
	},
	loop: true
});

var imga_swiper = new Swiper('.images_slider.no-loop', {
	slidesPerView: 1,
	spaceBetween: 20,
	navigation: {
        nextEl: '.swiper-button-img-next',
        prevEl: '.swiper-button-img-prev',
	},
	mousewheel: {
		forceToAxis: true, 
	},
});

var imga_swiper_loop = new Swiper('.images_slider.loop', {
	slidesPerView: 1,
	spaceBetween: 20,
	navigation: {
        nextEl: '.swiper-button-img-next',
        prevEl: '.swiper-button-img-prev',
	},
	mousewheel: {
		forceToAxis: true, 
	},
	loop: true,
});