var $ = require("../../../config/node_modules/jquery");

$("input[type='file']").each(function( index, field) {
    $(field).on('click', function(e){
        if ($(field).hasClass("trash")) {
            $(field).val(null);
            $(field).removeClass('trash'); 
            e.preventDefault();      
        } 
    });

    $(field).change( function(){
        if( field.files.length == 0 ){
            $(field).removeClass('trash');
        } 
        else {
            $(field).addClass('trash');
        }
    }); 
})