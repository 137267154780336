import '../../config/node_modules/regenerator-runtime/runtime';
import {contentAnimationIn} from './scripts/animationTitle';
import {gallery} from './scripts/gallery';
import './scripts/functions';
import './scripts/updatecss';
import {navigation} from './scripts/navigation';
import './scripts/swiper';
import './scripts/form';
import {filter} from './scripts/filter'; 
import {general} from './scripts/general';

import {observeImages} from './scripts/lazyload';
// import { authentication } from './scripts/authentication/authentication';
import './styles/style';

contentAnimationIn();
navigation();
filter();
general();
observeImages();
gallery();